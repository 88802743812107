<template>
  <div class="policy-background">
    <div class="content-div" style="height: 776px;overflow-y: scroll;">
      <!-- <iframe src="/static/zcbj.html" width="100%" height="100%" frameborder="0"></iframe> -->
      <h1 class="title-name">{{titleName}}</h1>
      <div class="content-content" v-html="content" style="width:98%;height:100%">{{content}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Qindan",
  data() {
    return {
      titleName: "上海市智能工厂名单",
      content: `<div align="center">
      <h2>2020年推荐20家智能工厂名单</h2>
      <div align="center">
<table class="MsoNormalTable" border="1" cellspacing="0" width="100%" bordercolor="white">
<tbody>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">序号</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">企业(工厂)名称</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">所属区域</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">所属行业</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">1</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上汽大众汽车有限公司</p>
<p align="center">（MEB智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">嘉定区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">汽车</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">2</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上汽通用汽车有限公司</p>
<p align="center">（凯迪拉克数字化工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">汽车</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">3</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海汽车集团股份有限公司乘用车公司</p>
<p align="center">（临港基地数字化工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">汽车</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">4</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海纳铁福传动系统有限公司</p>
<p align="center">（康桥数字化工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">汽车</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">5</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海延锋金桥汽车饰件系统有限公司</p>
<p align="center">（金桥智能制造工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">汽车</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">6</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海剑桥科技股份有限公司</p>
<p align="center">（剑桥科技智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">闵行区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">电子信息</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">7</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海兰宝传感科技股份有限公司</p>
<p align="center">（智能传感器制造数字化车间）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">奉贤区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">电子信息</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">8</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海微电子装备有限公司</p>
<p align="center">（超精密复杂集成电路专用装备智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">电子信息</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">9</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海新时达机器人有限公司</p>
<p align="center">（机器人智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">嘉定区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">高端装备</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">10</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海电气电站设备有限公司上海发电机厂</p>
<p align="center">（大型汽轮发电机智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">闵行区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">高端装备</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">11</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海外高桥造船有限公司</p>
<p align="center">（智能薄板生产车间）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">高端装备</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">12</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海振华重工（集团）股份有限公司</p>
<p align="center">（大型港口机械箱梁钢结构智能生产车间）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">高端装备</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">13</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">中国商飞上海飞机制造有限公司</p>
<p align="center">（航空零部件数控智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">浦东新区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">航空航天</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">14</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海航天设备制造总厂有限公司</p>
<p align="center">（航天器复杂构件智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">闵行区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">航空航天</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">15</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海东富龙科技股份有限公司</p>
<p align="center">（生物制药系统装备智能生产车间）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">闵行区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">生物医药</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">16</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海凯宝药业股份有限公司</p>
<p align="center">（中药生产数字化工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">奉贤区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">生物医药</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">17</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海联影医疗科技有限公司</p>
<p align="center">（高端医疗影像设备智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">嘉定区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">生物医药</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">18</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">宝山钢铁股份有限公司</p>
<p align="center">（冷轧智能生产车间）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">宝山区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">绿色化工、新材料</p>
</td>
</tr>
<tr>
<td valign="center" nowrap="nowrap" width="42">
<p align="center">19</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">上海华谊新材料有限公司</p>
<p align="center">（华谊新材料智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">奉贤区</p>
</td>
<td valign="center" nowrap="nowrap" width="78">
<p align="center">绿色化工、新材料</p>
</td>
</tr>
<tr style="height: 31.2pt;">
<td valign="center" nowrap="nowrap" width="42">
<p align="center">20</p>
</td>
<td valign="center" nowrap="nowrap" width="234">
<p align="center">光明乳业股份有限公司</p>
<p align="center">（乳制品全产业链智能工厂）</p>
</td>
<td valign="center" nowrap="nowrap" width="68">
<p align="center">闵行区</p>
</td>
<td style="width: 78pt; padding: 0pt 5.4pt; border-left: none; border-right-width: 1pt; border-right-color: windowtext; border-top: none; border-bottom-width: 1pt; border-bottom-color: windowtext;" valign="center" nowrap="nowrap" width="78">
<p class="15" style="text-align: center;" align="center">快消品、节能环保</p>
</td>
</tr>
</tbody>
</table>
 <h2>2021年上海市智能工厂企业名单</h2>
<table class="MsoNormalTable" border="1" cellspacing="0" width="100%" bordercolor="white">
<tbody>
<tr>
<td colspan="6" valign="top" width="496">
<p align="center">国家级标杆性智能工厂</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">序号</p>
</td>
<td valign="top" width="212">
<p align="center">企业名称</p>
</td>
<td valign="top" width="63">
<p align="center">所属区域</p>
</td>
<td valign="top" width="70">
<p align="center">隶属集团</p>
</td>
<td valign="top" width="58">
<p align="center">所属行业</p>
</td>
<td valign="top" width="58">
<p align="center">企业性质</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">1</p>
</td>
<td valign="center" width="212">
<p align="center">上海汽车集团股份有限公司乘用车公司</p>
<p align="center">（上汽乘用车临港数智工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p>上海汽车集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">2</p>
</td>
<td valign="center" width="212">
<p align="center">上海华谊新材料有限公司</p>
<p align="center">（华谊新材料智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">奉贤区</p>
</td>
<td valign="center" width="70">
<p>上海华谊（集团）股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">先进材料</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td colspan="6" valign="top" width="496">
<p align="center">上海市标杆性智能工厂</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">序号</p>
</td>
<td valign="top" width="212">
<p align="center">企业名称</p>
</td>
<td valign="top" width="63">
<p align="center">所属区域</p>
</td>
<td valign="top" width="70">
<p align="center">隶属集团</p>
</td>
<td valign="top" width="58">
<p align="center">所属行业</p>
</td>
<td valign="top" width="58">
<p>企业性质</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">1</p>
</td>
<td valign="center" width="212">
<p align="center">上海新时达机器人有限公司</p>
<p align="center">（上海新时达机器人智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center">上海新时达电气股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">2</p>
</td>
<td valign="center" width="212">
<p align="center">上海海立（集团）股份有限公司</p>
<p align="center">（上海海立智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">3</p>
</td>
<td valign="center" width="212">
<p align="center">安波福中央电气（上海）有限公司</p>
<p align="center">（上海安波福连接器智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center"></p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">独资</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">4</p>
</td>
<td valign="center" width="212">
<p align="center">上海仪电显示材料有限公司</p>
<p align="center">（显示材料柔性制造智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">上海仪电电子(集团)有限公司</p>
<p align="center">（上海仪电显示材料柔性智能工厂）</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">5</p>
</td>
<td valign="center" width="212">
<p align="center">宝山钢铁股份有限公司</p>
<p align="center">（宝钢股份冷轧C008智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">宝山区</p>
</td>
<td valign="center" width="70">
<p align="center">中国宝武钢铁集团有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">先进材料</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td colspan="5" valign="top" width="438">
<p align="center">上海市智能工厂</p>
</td>
<td valign="top" width="58">
<p align="center">&nbsp;</p>
</td>
</tr>
<tr>
<td valign="top" width="32">
<p align="center">序号</p>
</td>
<td valign="top" width="212">
<p align="center">企业名称</p>
</td>
<td valign="top" width="63">
<p align="center">所属区域</p>
</td>
<td valign="top" width="70">
<p align="center">隶属集团</p>
</td>
<td valign="top" width="58">
<p align="center">所属行业</p>
</td>
<td valign="top" width="58">
<p align="center">企业性质</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">1</p>
</td>
<td valign="center" width="212">
<p align="center">上海柴油机股份有限公司</p>
<p align="center">（D20智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">杨浦区</p>
</td>
<td valign="center" width="70">
<p>上海汽车集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">2</p>
</td>
<td valign="center" width="212">
<p align="center">上海航天精密机械研究所</p>
<p align="center">（航天离散制造智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p>上海航天局</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">3</p>
</td>
<td valign="center" width="212">
<p align="center">上海航天电子通讯设备研究所</p>
<p align="center">（微波组件智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p>上海航天局</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">4</p>
</td>
<td valign="center" width="212">
<p align="center">上海三菱电梯有限公司</p>
<p align="center">（电梯智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p>上海电气（集团）总公司</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">5</p>
</td>
<td valign="center" width="212">
<p align="center">上海烟草机械有限责任公司</p>
<p align="center">（&ldquo;5G+无人值守&rdquo;智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">中国烟草机械集团有限责任公司</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">6</p>
</td>
<td valign="center" width="212">
<p align="center">上海阿波罗机械股份有限公司</p>
<p align="center">（高可靠性产品智能制造工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">奉贤区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">7</p>
</td>
<td valign="center" width="212">
<p align="center">上海真兰仪表科技股份有限公司</p>
<p align="center">（智慧燃气计量仪表智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">青浦区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">8</p>
</td>
<td valign="center" width="212">
<p align="center">中科新松有限公司</p>
<p align="center">（新松协作机器人智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">沈阳新松机器人集团</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">9</p>
</td>
<td valign="center" width="212">
<p align="center">上海施耐德工业控制有限公司</p>
<p align="center">（低压控制产品智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">普陀区</p>
</td>
<td valign="center" width="70">
<p>施耐德电气集团</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">10</p>
</td>
<td valign="center" width="212">
<p align="center">上海诺玛液压系统有限公司</p>
<p align="center">（高端液压阀智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">11</p>
</td>
<td valign="center" width="212">
<p align="center">上海诺雅克电气有限公司</p>
<p align="center">(高端电器智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p align="center">正泰集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">12</p>
</td>
<td valign="center" width="212">
<p align="center">上海昌强重工机械有限公司</p>
<p align="center">（上海昌强数联智造智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">临港新片区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">高端装备</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">13</p>
</td>
<td valign="center" width="212">
<p align="center">特斯拉（上海）有限公司</p>
<p align="center">（特斯拉上海超级工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">临港新片区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">14</p>
</td>
<td valign="center" width="212">
<p align="center">华域视觉科技（上海）有限公司</p>
<p align="center">（嘉定数字化智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center">华域汽车集团有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">15</p>
</td>
<td valign="center" width="212">
<p align="center">上海保隆汽车科技股份有限公司</p>
<p align="center">（车用传感器智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">16</p>
</td>
<td valign="center" width="212">
<p align="center">延锋彼欧（上海）汽车外饰系统有限公司</p>
<p align="center">（安亭数字化工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center">延锋彼欧汽车外饰系统有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">17</p>
</td>
<td valign="center" width="212">
<p align="center">上海汽车变速器有限公司</p>
<p align="center">（上海基地数字化智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center">上汽集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">18</p>
</td>
<td valign="center" width="212">
<p align="center">联合汽车电子有限公司</p>
<p align="center">(汽车驱动科技上海智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">上海汽车集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">19</p>
</td>
<td valign="center" width="212">
<p align="center">上海汇众汽车制造有限公司</p>
<p align="center">(底盘系统数字化智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">上海汽车集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">20</p>
</td>
<td valign="center" width="212">
<p align="center">上海汽车制动系统有限公司</p>
<p align="center">（汽车制动系统智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center">上海汽车集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">汽车</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">21</p>
</td>
<td valign="center" width="212">
<p align="center">上海君实生物工程有限公司</p>
<p align="center">(君实生物临港智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">临港新片区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">生命健康</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">22</p>
</td>
<td valign="center" width="212">
<p align="center">上海上药第一生化药业有限公司</p>
<p align="center">(高端注射剂智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">上海医药集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">生命健康</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">23</p>
</td>
<td valign="center" width="212">
<p align="center">上海森松制药设备工程有限公司</p>
<p align="center">(森松制药高端装备智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">生命健康</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">24</p>
</td>
<td valign="center" width="212">
<p align="center">上海勃林格殷格翰药业有限公司</p>
<p align="center">(发酵纯化生物制药智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">生命健康</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">25</p>
</td>
<td valign="center" width="212">
<p align="center">上海华力微电子有限公司</p>
<p align="center">(集成电路数字化智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">国有</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">26</p>
</td>
<td valign="center" width="212">
<p align="center">上海晨阑光电器件有限公司</p>
<p align="center">（照明模组智能工厂）</p>
</td>
<td valign="center" width="63">
<p align="center">嘉定区</p>
</td>
<td valign="center" width="70">
<p align="center">上海仪电电子(集团)有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">27</p>
</td>
<td valign="center" width="212">
<p align="center">英业达（上海）科技有限公司</p>
<p align="center">(英业达电子信息品智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">英业达集团</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">28</p>
</td>
<td valign="center" width="212">
<p align="center">上海天马微电子有限公司</p>
<p align="center">(专业显示器件柔性智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">天马微电子集团</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">29</p>
</td>
<td valign="center" width="212">
<p align="center">上海华虹宏力半导体制造有限公司</p>
<p align="center">(华虹宏力智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">上海华虹（集团）有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">30</p>
</td>
<td valign="center" width="212">
<p align="center">英华达（上海）科技有限公司</p>
<p align="center">(消费电子产品智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">英业达集团</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">31</p>
</td>
<td valign="center" width="212">
<p align="center">上海超硅半导体股份有限公司</p>
<p align="center">(大尺寸IC级硅片智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">32</p>
</td>
<td valign="center" width="212">
<p align="center">上海良信电器股份有限公司</p>
<p align="center">(低压电器智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">33</p>
</td>
<td valign="center" width="212">
<p align="center">奥特斯（中国）有限公司</p>
<p align="center">(新一代印制电路板智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">奥地利科技与系统技术股份公司</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">34</p>
</td>
<td valign="center" width="212">
<p align="center">达功（上海）电脑有限公司</p>
<p align="center">(基于工业互联网的智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p align="center">广达电脑集团</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">35</p>
</td>
<td valign="center" width="212">
<p align="center">尼西半导体科技（上海）有限公司</p>
<p align="center">(功率半导体封测智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">松江区</p>
</td>
<td valign="center" width="70">
<p align="center">AOS集团</p>
</td>
<td valign="center" width="58">
<p align="center">电子信息</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">36</p>
</td>
<td valign="center" width="212">
<p align="center">宝武碳业科技股份有限公司</p>
<p align="center">(新型碳材料智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">宝山区</p>
</td>
<td valign="center" width="70">
<p align="center">中国宝武钢铁集团有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">先进材料</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">37</p>
</td>
<td valign="center" width="212">
<p align="center">上海氯碱化工股份有限公司</p>
<p align="center">(上海氯碱智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">金山区</p>
</td>
<td valign="center" width="70">
<p align="center">上海华谊（集团）有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">先进材料</p>
</td>
<td valign="center" width="58">
<p align="center">国企</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">38</p>
</td>
<td valign="center" width="212">
<p align="center">上海米其林轮胎有限公司</p>
<p align="center">(子午线轮胎智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">先进材料</p>
</td>
<td valign="center" width="58">
<p align="center">外资</p>
</td>
</tr>
<tr>
<td valign="center" width="32">
<p align="center">39</p>
</td>
<td valign="center" width="212">
<p align="center">开能健康科技集团股份有限公司</p>
<p align="center">(开能健康净水智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">浦东新区</p>
</td>
<td valign="center" width="70">
<p align="center">&nbsp;</p>
</td>
<td valign="center" width="58">
<p align="center">时尚消费品</p>
</td>
<td valign="center" width="58">
<p align="center">民营</p>
</td>
</tr>
<tr style="height: 19.85pt;">
<td valign="center" width="32">
<p align="center">40</p>
</td>
<td valign="center" width="212">
<p align="center">上海紫丹食品包装印刷有限公司</p>
<p align="center">(紫丹食品纸包装智能工厂)</p>
</td>
<td valign="center" width="63">
<p align="center">闵行区</p>
</td>
<td valign="center" width="70">
<p align="center">上海紫江企业集团股份有限公司</p>
</td>
<td valign="center" width="58">
<p align="center">时尚消费品</p>
</td>
<td style="width: 58.1pt; padding: 0pt; border-left: none; border-right: 1pt solid #000000; border-top: none; border-bottom: 1pt solid #000000;" valign="center" width="58">
<p class="MsoNormal" style="margin: 0pt 0pt 0.0001pt; text-align: center; font-family: 等线; font-size: 10.5pt; vertical-align: middle;" align="center">民营</p>
</td>
</tr>
</tbody>
</table>
</div>
</div>`
    };
  },
  props: {
    msg: String
  },
  created() {
    this.getData(123);
  },
  methods: {
    getData(data) {}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.title-name,
.content-content {
  color: white;
}
.policy-background {
  width: 1440px;
  height: 796px;
  background: #000066;
  margin-top: 132px;
  padding: 10px;
  .content-div {
    width: 1420px;
    height: 776px;
    margin: 0 10px;
    // overflow: scroll;
    // @thumb_scroll();
  }
}
</style>
